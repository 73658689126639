import { useEffect, useState, useRef } from "react";
import { getProducts } from "../api";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";

import logo from "../img/logo.png";
import arrowIcon from "../img/arrow-right.svg";

import { Header } from "./header/Header";
import { useAppContext } from "./contextWrapper/AppContext";
import { convertProducts } from "../utils/structures";

function formatNumberWithSpaces(num) {
  return parseInt(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Catalog = ({ paymentStatus }) => {
  const nav = useNavigate();

  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showTopButton, setShowTopButton] = useState(false);
  const menuContainersRefs = useRef([]);
  const { cart, setCart } = useAppContext();

  const userStorage = localStorage.getItem("user");
  const actualUser = JSON.parse(userStorage);

  useEffect(() => {
    if (paymentStatus === "success") {
      localStorage.removeItem("cart");
      toast.success(
        "Заказ оформлен, наш менеджер в ближайшее время с Вами свяжется",
        {
          duration: 4000,
        }
      );
    } else if (paymentStatus === "error") {
      toast.error("К сожалению, произошла ошибка при оформлении заказа", {
        duration: 4000,
      });
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (!actualUser) {
      nav("/auth");
      return;
    }
    fetchData();

    return () => {};
  }, []);

  const fetchData = async () => {
    const products = await getProducts();
    setProducts(products);
  };

  useEffect(() => {
    const i = setInterval(() => {
      if (window.pageYOffset > 100) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    }, 100);

    return () => {
      clearInterval(i);
    };
  }, []);

  useEffect(() => {
    let price = 0;
    cart.forEach((product) => {
      price += product.price * product.quantity * product.inBox;
    });
    setTotalPrice(parseInt(price));
    localStorage.setItem("cart", JSON.stringify(cart));

    // if (price > 0) {
    //   setShowButton(true);
    // } else {
    //   setShowButton(false);
    // }
  }, [cart]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const navigationRect = navigationRef.current.getBoundingClientRect();

  //     for (let i = 0; i < menuContainersRefs.current.length; i++) {
  //       const containerRect =
  //         menuContainersRefs.current[i].getBoundingClientRect();

  //       if (containerRect.top >= navigationRect.top) {
  //         setActiveCategory(i);
  //         break;
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleCategoryClick = (index) => {
  //   // setActiveCategory(index);
  //   menuContainersRefs.current[index].scrollIntoView({ behavior: "smooth" });
  // };

  const handleCategoryClick = (index) => {
    if (menuContainersRefs.current[index]) {
      menuContainersRefs.current[index].scrollIntoView({ behavior: "smooth" });
    } else {
      console.error(`No element found at index ${index}`);
    }
  };

  const goToCart = () => {
    nav("/cart");
  };

  return (
    <div className="container">
      <Header paymentStatus={paymentStatus} />
      {/* <div className="menu"> */}
      {/* <div className="navigation" ref={navigationRef}>
          {products.map((category, index) => (
            <div
              key={index}
              className={`navItem ${activeCategory === index && "active"}`}
              id={`category${index}`}
              onClick={() => handleCategoryClick(index)}
            >
              {category.categoryName}
            </div>
          ))}
        </div> */}

      <div
        className="banner"
        onClick={() => handleCategoryClick(products.length - 1)}
      >
        <img src="/banner.jpg" />
      </div>

      {products
        .map((categoryy, index) => ({ ...categoryy, originalIndex: index }))
        .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
        .map((category, i) => {
          const filteredProducts = category.products.filter(
            (product) =>
              product.price &&
              parseInt(product.price) !== 0 &&
              product.inStock &&
              parseInt(product.inStock) !== 0
          );
          if (filteredProducts.length === 0) return null;
          return (
            <div key={i}>
              <div
                className="title"
                id={`category${category.originalIndex}`}
                onClick={() => {
                  nav("/category/" + category.originalIndex);
                }}
              >
                {category.categoryName}
                <img src={arrowIcon} />
              </div>
              <div
                className="menuContainer"
                ref={(ref) => (menuContainersRefs.current[category.id] = ref)}
                key={category.id}
              >
                {category.products
                  .filter((product) => {
                    if (
                      product.price &&
                      parseInt(product.price) !== 0 &&
                      product.inStock &&
                      parseInt(product.inStock) !== 0
                    ) {
                      return product;
                    }
                  })
                  .slice(0, 9)
                  .map((product, index) => {
                    const inCart = cart.find(
                      (_product) => _product.id == product.id
                    );
                    // if (!product.price || parseInt(product.price) === 0) return;
                    // if (!product.inStock || parseInt(product.inStock) === 0)
                    //   return;
                    return (
                      <div key={index} className="menuItem">
                        <div className="info">
                          <img
                            onClick={() => {
                              localStorage.setItem(
                                "product",
                                JSON.stringify({
                                  ...product,
                                  inCart: inCart ? inCart.quantity : 0,
                                })
                              );
                              nav(`/product/${product.id}`);
                            }}
                            src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                            alt="food"
                            className="picture"
                          />
                          <div className="name">{product.article}</div>
                          <div className="weight">
                            PM3: {product.inBox} шт
                            <br />
                          </div>
                        </div>
                        {inCart ? (
                          <div className="add catalog_counter">
                            <div
                              className="cic-minus"
                              onClick={() => {
                                let minusAmount = 1;
                                if (
                                  parseInt(product.inBox) >=
                                  parseInt(inCart.quantity * product.inBox)
                                ) {
                                  minusAmount =
                                    1 /
                                    (parseInt(product.inBox) /
                                      parseInt(product.inPackage));
                                } else if (
                                  parseInt(product.inBox) +
                                    parseInt(product.inTheBox) <=
                                  parseInt(inCart.quantity * product.inBox)
                                ) {
                                  minusAmount =
                                    parseInt(product.inTheBox) /
                                    parseInt(product.inBox);
                                }

                                const newQuantity =
                                  inCart.quantity.toFixed(3) >
                                  minusAmount.toFixed(3)
                                    ? parseFloat(inCart.quantity - minusAmount)
                                    : 0;
                                if (newQuantity > 0) {
                                  setCart(
                                    cart.map((_product) => {
                                      if (_product.id === product.id) {
                                        return {
                                          ..._product,
                                          quantity: newQuantity,
                                        };
                                      }
                                      return _product;
                                    })
                                  );
                                } else {
                                  setCart(
                                    cart.filter(
                                      (_product) => _product.id != product.id
                                    )
                                  );
                                }
                              }}
                            >
                              <svg
                                className="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0"
                                y="0"
                                viewBox="0 0 341.333 341.333"
                                style={{
                                  "enable-background": "new 0 0 512 512",
                                }}
                                xmlSpace="preserve"
                              >
                                <rect
                                  y="149.333"
                                  width="341.333"
                                  height="42.667"
                                  fill="#f6f6f6"
                                ></rect>
                              </svg>
                            </div>
                            <div className="amount">
                              {
                                //tt
                                parseInt(inCart.quantity * product.inBox) %
                                  product.inPackage !==
                                0
                                  ? Math.ceil(inCart.quantity * product.inBox)
                                  : parseInt(inCart.quantity * product.inBox)
                              }
                            </div>
                            <div
                              className="cic-plus"
                              onClick={() => {
                                if (
                                  inCart.quantity <
                                  product.inStock *
                                    (parseInt(product.inTheBox) /
                                      parseInt(product.inBox))
                                ) {
                                  let incrementAmount = 1;
                                  if (
                                    parseInt(product.inBox) >
                                    parseInt(inCart.quantity * product.inBox)
                                  ) {
                                    incrementAmount =
                                      1 /
                                      (parseInt(product.inBox) /
                                        parseInt(product.inPackage)); //parseInt(product.inPackage)
                                  }
                                  setCart(
                                    cart.map((_product) => {
                                      if (_product.id === product.id) {
                                        return {
                                          ..._product,
                                          quantity: parseFloat(
                                            (
                                              _product.quantity +
                                              incrementAmount
                                            ).toFixed(2)
                                          ),
                                        };
                                      }
                                      return _product;
                                    })
                                  );
                                }
                              }}
                            >
                              <svg
                                className="svg"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0"
                                y="0"
                                viewBox="0 0 341.4 341.4"
                                style={{
                                  "enable-background": "new 0 0 512 512",
                                }}
                                xmlSpace="preserve"
                              >
                                <polygon
                                  points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 341.4,149.4"
                                  fill="#f6f6f6"
                                  className=""
                                ></polygon>
                              </svg>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="price"
                            onClick={() => {
                              localStorage.setItem(
                                "product",
                                JSON.stringify({
                                  ...product,
                                })
                              );
                              nav("/product/" + product.id);
                            }}
                          >
                            {formatNumberWithSpaces(Number(product.price))} ₽
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}

      {totalPrice > 0 && (
        <div
          className="go-to-order-wrap "
          onClick={() => {
            nav("/cart");
          }}
        >
          Заказ на {formatNumberWithSpaces(totalPrice)} ₽
        </div>
      )}
      {showTopButton > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: "#2a2a2a",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            zIndex: 1000,
            display: "flex",
          }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.23191 10.6401C5.58591 11.0641 6.21591 11.1221 6.63991 10.7681L10.9999 7.13512V19.0001C10.9999 19.5521 11.4479 20.0001 11.9999 20.0001C12.5519 20.0001 12.9999 19.5521 12.9999 19.0001V7.13512L17.3599 10.7681C17.7849 11.1221 18.4149 11.0641 18.7679 10.6401C19.1219 10.2161 19.0649 9.58512 18.6399 9.23212L12.6399 4.23212C12.5929 4.19212 12.5369 4.17312 12.4849 4.14312C12.4439 4.11912 12.4079 4.09112 12.3629 4.07312C12.2459 4.02712 12.1239 4.00012 11.9999 4.00012C11.8759 4.00012 11.7539 4.02712 11.6369 4.07312C11.5919 4.09112 11.5559 4.11912 11.5149 4.14312C11.4629 4.17312 11.4069 4.19212 11.3599 4.23212L5.35991 9.23212C4.93591 9.58512 4.87791 10.2161 5.23191 10.6401Z"
              fill="#fff"
            />
            <mask
              id="mask0_7_4676"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="4"
              width="15"
              height="17"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.23191 10.6401C5.58591 11.0641 6.21591 11.1221 6.63991 10.7681L10.9999 7.13512V19.0001C10.9999 19.5521 11.4479 20.0001 11.9999 20.0001C12.5519 20.0001 12.9999 19.5521 12.9999 19.0001V7.13512L17.3599 10.7681C17.7849 11.1221 18.4149 11.0641 18.7679 10.6401C19.1219 10.2161 19.0649 9.58512 18.6399 9.23212L12.6399 4.23212C12.5929 4.19212 12.5369 4.17312 12.4849 4.14312C12.4439 4.11912 12.4079 4.09112 12.3629 4.07312C12.2459 4.02712 12.1239 4.00012 11.9999 4.00012C11.8759 4.00012 11.7539 4.02712 11.6369 4.07312C11.5919 4.09112 11.5559 4.11912 11.5149 4.14312C11.4629 4.17312 11.4069 4.19212 11.3599 4.23212L5.35991 9.23212C4.93591 9.58512 4.87791 10.2161 5.23191 10.6401Z"
                fill="#fff"
              />
            </mask>
            <g mask="url(#mask0_7_4676)">
              <rect width="24" height="24" fill="#fff" />
            </g>
          </svg>
        </div>
      )}
      {/* </div> */}
      <footer>
        <div className="footer">
          <img src={logo} className="logoIcon" alt="logoIcon" />

          <div
            className="phone"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              color: "white",
              gap: "10px",
              marginTop: "45px",
            }}
          >
            Техническая поддержка:
            <a href={"tel:+79786121068"}>+79786121068</a>
            <a href="mailto:support@spruton.shop">support@spruton.shop</a>
          </div>
        </div>
        <p>
          Мы выбрали{" "}
          <a href="https://spruton.shop/" target="_blank" rel="noreferrer">
            СПРУТОН МАРКЕТ
          </a>{" "}
          для создания магазина
        </p>
      </footer>
      <Toaster />
    </div>
  );
};

export default Catalog;
