import { useEffect, useMemo, useState } from "react";
import { Header } from "../header/Header";
import "./product.css";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SpecRow } from "./SpecRow";
import { ButtonBlock } from "./ButtonBlock";
import logo from "../../img/logo.png";
import { AgeEnum } from "../../utils/structures";

export const NewProduct = () => {
  const nav = useNavigate();
  const [product, setProduct] = useState(null);
  const [isDescriptionBtn, setIsDescriptionBtn] = useState(1);
  const [isSizeBtn, setIsSizeBtn] = useState(1);
  const [isColorBtn, setIsColorBtn] = useState(1);
  console.log(product);

  useEffect(() => {
    const productLocal = JSON.parse(localStorage.getItem("product"));
    if (productLocal) {
      setProduct(productLocal);
    } else {
      nav("/");
    }
  }, []);

  const settings = useMemo(
    () => ({
      dots: product?.otherImages.length > 0 ? true : false,
      infinite: product?.otherImages.length > 0 ? true : false,
      arrows: product?.otherImages.length > 0 ? true : false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }),
    [product]
  );

  const colors = {
    red: 76,
    gray: 77,
    blue: 78,
    black: 79,
    green: 80,
    violet: 81,
    multicolor: 82,
    yellow: 84,
  };

  function getColorName(colorCode) {
    // Object.entries yordamida kalit-qiymat juftligini izlaymiz
    for (const [colorName, code] of Object.entries(colors)) {
      if (code === colorCode) {
        return colorName; // Rang nomini qaytarish
      }
    }
    return "Rang topilmadi"; // Agar mos rang bo'lmasa
  }

  return product ? (
    <div className="container box">
      <Header />
      <div className="content-wrapper">
        <div className="flex gap-10">
          <span
            className="gray-7d"
            onClick={() => nav("/category/" + product?.categoryID)}
          >
            {product?.categoryName}
          </span>
          <span className="gray-7d">{`> ${product?.article}`} </span>
        </div>
        <div className="product-block">
          <div className="slider">
            <Slider {...settings}>
              <div>
                <img
                  src={`https://shop-api.toyseller.site/api/image/${product.id}/${product.image}`}
                  alt={`image-${product.id}`}
                  className="image"
                />
              </div>
              {product?.otherImages
                ?.filter((item) => item != "")
                .map((slide, i) => (
                  <div key={i}>
                    <img
                      // src={`https://shop-api.toyseller.site/api/image/${product.id}/${slide}`}
                      src={`https://shop-api.toyseller.site/api/product_other_image/${product.id}/${slide}`}
                      alt={`image-${product.id}`}
                      className="image"
                    />
                  </div>
                ))}
            </Slider>
          </div>
          <div className="product-content">
            <div>
              <h3 className="sub-title">{product?.article}</h3>
              <div className="count-product">
                <span>РМЗ: {product.inBox} шт.</span>
                <span>В упаковке: {product?.inPackage} шт.</span>
                <span>Остаток: {product?.inStock} шт.</span>
              </div>
            </div>
            {/* <div className="flex gap-15 column">
              <h3 className="sub-title">Цвета</h3>
              <div className="flex gap-15">
                <div
                  className={`color-block ${
                    isColorBtn === 1 ? "activePr" : ""
                  }`}
                  onClick={() => setIsColorBtn(1)}
                />
                <div
                  className={`color-block ${
                    isColorBtn === 2 ? "activePr" : ""
                  }`}
                  onClick={() => setIsColorBtn(2)}
                />
                <div
                  className={`color-block ${
                    isColorBtn === 3 ? "activePr" : ""
                  }`}
                  onClick={() => setIsColorBtn(3)}
                />
                <div
                  className={`color-block ${
                    isColorBtn === 4 ? "activePr" : ""
                  }`}
                  onClick={() => setIsColorBtn(4)}
                />
                <div
                  className={`color-block ${
                    isColorBtn === 5 ? "activePr" : ""
                  }`}
                  onClick={() => setIsColorBtn(5)}
                />
              </div>
              <span className="gray-d9">Цвет: {product.textColor}</span>
            </div> */}
            <div
              style={{ display: product.categoryID == 40 ? "flex" : "none" }}
              className="flex gap-15 column"
            >
              <h3 className="sub-title">Размер</h3>
              <div className="flex gap-5">
                <div
                  className={`size-block ${isSizeBtn === 1 ? "activePr" : ""}`}
                  onClick={() => setIsSizeBtn(1)}
                >
                  <span className="size-letter">{product.shoeSizeRu}</span>
                  <div className="size-description"></div>
                </div>
                {/*
                <div
                  className={`size-block ${isSizeBtn === 1 ? "activePr" : ""}`}
                  onClick={() => setIsSizeBtn(1)}
                >
                  <span className="size-letter">S</span>
                  <div className="size-description">25х15х5 см</div>
                </div>
                
                 <div
                  className={`size-block ${isSizeBtn === 2 ? "activePr" : ""}`}
                  onClick={() => setIsSizeBtn(2)}
                >
                  <span className="size-letter">M</span>
                  <div className="size-description">35х25х10 см</div>
                </div>
                <div
                  className={`size-block ${isSizeBtn === 2 ? "activePr" : ""}`}
                  onClick={() => setIsSizeBtn(2)}
                >
                  <span className="size-letter">L</span>
                  <div className="size-description">45х35х10 см</div>
                </div> */}
              </div>
              {/* <span className="gray-d9 underline fs-18">Таблица размеров</span> */}
            </div>
            {/* небольшое описание */}
            {/* <div className="flex gap-15 column">
              <span className="gray-d9 fs-18">Бренд: ???</span>
              <h3 className="sub-title">Название ???</h3>
            </div> */}
            <div className="flex gap-20 column">
              <div className="flex gap-5">
                <button
                  className={`small-white-button ${
                    isDescriptionBtn === 1 ? "activePr" : ""
                  }`}
                  onClick={() => setIsDescriptionBtn(1)}
                >
                  Описание
                </button>
                <button
                  className={`small-white-button ${
                    isDescriptionBtn === 2 ? "activePr" : ""
                  }`}
                  onClick={() => setIsDescriptionBtn(2)}
                >
                  Характеристики
                </button>
              </div>
              <div className="description-block">
                {isDescriptionBtn === 1 && (
                  <p className="gray-d9">
                    {product?.description
                      ? product?.description
                      : "Описания нет"}
                  </p>
                )}
                {isDescriptionBtn === 2 && (
                  <>
                    <SpecRow label="Артикул" value={product?.article} />
                    <SpecRow label="Материал" value="???" />
                    <SpecRow
                      label="Возраст"
                      value={`от ${AgeEnum[product?.minKidAge]} лет`}
                    />
                    <SpecRow
                      label="Размер"
                      value={`${product?.shoeSizeLength}мм`}
                    />
                  </>
                )}
              </div>
            </div>
            {product?.keyWords.length > 0 && (
              <div className="flex gap-15 column">
                <h3 className="sub-title">Ищут по запросам:</h3>
                <div className="flex gap-5">
                  {product?.keyWords.map((el, i) => (
                    <div key={i} className="request-word">
                      {el}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <ButtonBlock product={product} />
          </div>
        </div>
      </div>
      <footer>
        <div className="footer mt-25">
          <img src={logo} className="logoIcon" alt="logoIcon" />

          <div
            className="phone"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              color: "white",
              gap: "10px",
              marginTop: "45px",
            }}
          >
            Техническая поддержка:
            <a href={"tel:+79786121068"}>+79786121068</a>
            <a href="mailto:support@spruton.shop">support@spruton.shop</a>
          </div>
        </div>
        <p>
          Мы выбрали{" "}
          <a href="https://spruton.shop/" target="_blank" rel="noreferrer">
            СПРУТОН МАРКЕТ
          </a>{" "}
          для создания магазина
        </p>
      </footer>
    </div>
  ) : null;
};
