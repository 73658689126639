import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import arrowIcon from "../../img/arrow-left.svg";
import arrow2Icon from "../../img/arrow-right.svg";
import { Header } from "../header/Header";
import { useAppContext } from "../contextWrapper/AppContext";
import "./OrderInfo.css";
import { DeleteIcon } from "../icons/DeleteIcon";
import { getDeclination } from "../../utils/getDeclination";
import { calculateQuantity } from "../../utils/calculatePrice";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const OrderInfo = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { userInfo } = useAppContext();

  const activeOrder = useMemo(
    () =>
      userInfo?.orders &&
      userInfo?.orders.find((order) => String(order.orderId) === id),
    [userInfo, id]
  );

  if (!activeOrder) {
    return null;
  }

  console.log(activeOrder);

  const totalPrice = activeOrder.products.reduce((acc, product) => {
    acc +=
      parseInt(product.quantity * product.inBox) % product.inPackage !== 0
        ? Math.ceil(product.quantity * product.inBox) * product.price
        : parseInt(product.quantity * product.inBox) * product.price;
    return acc;
  }, 0);

  const shtug = calculateQuantity(activeOrder.products[0]);

  return (
    <div className="container">
      <Header />
      {/* <p>
        Дата заказа: {new Date(activeOrder.orderDate * 1000).toLocaleString()}
      </p> */}

      <div className="card-block">
        <div className="left-card-block">
          <div className="card-block-element list">
            <div className="title-block">
              <div className="card-block-element-title">
                <h3>Заказ №{activeOrder.orderId}</h3>
                <span>
                  {getDeclination(activeOrder.products.length, [
                    "товар",
                    "товара",
                    "товаров",
                  ])}{" "}
                </span>
              </div>
            </div>
            <div className="card-block-list orderInfo">
              {activeOrder &&
                activeOrder.products.map((product, index) => (
                  <div
                    key={index}
                    className="cart-item-row"
                    style={{ borderBottom: "2px solid #1c1c1c" }}
                    onClick={() => {
                      localStorage.setItem("product", JSON.stringify(product));
                      nav(`/product/${product.productID}`);
                    }}
                  >
                    <div
                      className="cart-item-picture"
                      onClick={() => {
                        if (parseInt(product.inStock <= 0)) return;
                        localStorage.setItem(
                          "product",
                          JSON.stringify({
                            ...product,
                          })
                        );
                        nav("/product/" + product.productID);
                      }}
                    >
                      <img
                        src={`https://shop-api.toyseller.site/api/image/${product.productID}/${product.image}`}
                        alt="picture"
                      />
                    </div>
                    <div className="cart-item-data">
                      <div className="cart-item-label">
                        <h3>{product.article}</h3>
                        <div className="cart-item-caption">
                          <span>характеристики добавить</span>
                          <span>Кол-во: {calculateQuantity(product)} шт</span>
                        </div>
                        <h3>
                          {formatNumberWithSpaces(
                            parseInt(product.quantity * product.inBox) %
                              product.inPackage !==
                              0
                              ? Math.ceil(product.quantity * product.inBox) *
                                  product.price
                              : parseInt(product.quantity * product.inBox) *
                                  product.price
                          )}{" "}
                          ₽
                        </h3>
                      </div>
                      <div className="cart-right-block">
                        <img src={arrow2Icon} alt="arrow2Icon" />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="receipt">
          <div className="date">
            {(() => {
              const date = new Date(activeOrder.orderDate * 1000);
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Oylarda 0 dan boshlanadi
              const year = date.getFullYear();
              const hours = date.getHours().toString().padStart(2, "0");
              const minutes = date.getMinutes().toString().padStart(2, "0");

              return `${day}.${month}.${year} ${hours}:${minutes}`;
            })()}
          </div>

          <div className="item-row">
            <div className="item-label">Товары, {shtug} шт.</div>
            <div className="item-amount">
              {formatNumberWithSpaces(totalPrice)} ₽
            </div>
          </div>

          <div className="item-row">
            <div className="item-label">Моя скидка</div>
            <div className="item-amount">
              {formatNumberWithSpaces(activeOrder.discount)} ₽
            </div>
          </div>

          <div className="total-row">
            <div className="total-label">Итого:</div>
            <div className="total-amount">
              {formatNumberWithSpaces(activeOrder.total)} ₽
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
